import { AUTHORIZED_ROUTES } from '../view/constants';

export const MENU_ITEM_TODAY = {
  title: 'Hoje',
  path: AUTHORIZED_ROUTES.INFORMATION.TODAY
};

export const MENU_ITEM_ARRIVALS = {
  title: 'Vai chegar',
  path: AUTHORIZED_ROUTES.TRACKING.ARRIVALS,
  fsCanFunction: 'loggiPonto'
};

export const MENU_ITEM_NEW_BASE = {
  title: 'Na base',
  path: AUTHORIZED_ROUTES.INFORMATION.BASE
};

export const MENU_ITEM_IN_STREET = {
  title: 'Na rua',
  path: AUTHORIZED_ROUTES.TRACKING.IN_STREET
};

export const MENU_ITEM_FINISHED = {
  title: 'Finalizados',
  path: AUTHORIZED_ROUTES.TRACKING.FINISHED
};

export const MENU_ITEM_DELIVERERS = {
  title: 'Distribuir',
  path: AUTHORIZED_ROUTES.MOVE.DELIVERERS,
  fsCanFunction: 'loggiPonto',
  iconName: 'DirectionCircleUpRight'
};

export const MENU_GROUP_TRACKING_OPERATOR = [
  MENU_ITEM_TODAY,
  MENU_ITEM_IN_STREET,
  MENU_ITEM_NEW_BASE
];

export const MENU_GROUP_TRACKING_NEW_BASE = [
  {
    title: 'Hoje',
    path: AUTHORIZED_ROUTES.INFORMATION.TODAY,
    iconName: 'Calendar'
  },
  {
    title: 'Vai chegar',
    path: AUTHORIZED_ROUTES.TRACKING.ARRIVALS,
    fsCanFunction: 'loggiPonto',
    iconName: 'DirectionCircleDownRight'
  },
  {
    title: 'Na base',
    path: AUTHORIZED_ROUTES.INFORMATION.BASE,
    iconName: 'Base05'
  },
  {
    title: 'Para transferir',
    path: AUTHORIZED_ROUTES.TRACKING.TO_TRANSFER
  },
  {
    title: 'Na rua',
    path: AUTHORIZED_ROUTES.TRACKING.IN_STREET,
    fsCanFunction: 'loggiPonto',
    iconName: 'Map02'
  },
  {
    title: 'Finalizados',
    path: AUTHORIZED_ROUTES.TRACKING.FINISHED,
    iconName: 'PackageCheck'
  }
];

const OFFER_ITEM = {
  title: 'Ofertas',
  path: AUTHORIZED_ROUTES.OFFER.LIST,
  fsCanFunction: 'loggiPonto',
  iconName: 'Sale02'
};

export const MENU_GROUP_TRACKING_WITH_OFFER_NEW_BASE = (() => {
  const menuGroup = [...MENU_GROUP_TRACKING_NEW_BASE];
  menuGroup.splice(
    MENU_GROUP_TRACKING_NEW_BASE.findIndex(i => i.title === 'Hoje') + 1,
    0,
    OFFER_ITEM
  );
  return menuGroup;
})();

export const MENU_GROUP_CARRIER = [
  {
    title: 'Hoje',
    path: AUTHORIZED_ROUTES.INFORMATION.TODAY
  },
  OFFER_ITEM,
  {
    title: 'Na rua',
    path: AUTHORIZED_ROUTES.TRACKING.IN_STREET
  },
  {
    title: 'Finalizados',
    path: AUTHORIZED_ROUTES.TRACKING.FINISHED
  }
];

export const MENU_GROUP_MOVE_DELIVERERS = [MENU_ITEM_DELIVERERS];
export const MENU_GROUP_MOVE = [MENU_ITEM_DELIVERERS];

export const MENU_GROUP_FINANCE = [
  {
    title: 'Valores',
    path: AUTHORIZED_ROUTES.FINANCE.SUMMARY,
    iconName: 'WalletOpened'
  },
  {
    title: 'Aberto',
    path: AUTHORIZED_ROUTES.FINANCE.OPEN,
    iconName: 'WalletOpened'
  },
  {
    title: 'Fechado',
    path: AUTHORIZED_ROUTES.FINANCE.CLOSE,
    iconName: 'WalletClosed'
  },
  {
    title: 'Anteriores',
    path: AUTHORIZED_ROUTES.FINANCE.PREVIOUS,
    iconName: 'WalletClosed'
  }
];

export const MENU_GROUP_DRIVER_TRACKING = [
  {
    title: 'Atividades',
    path: AUTHORIZED_ROUTES.DRIVERS_TRACKING
  }
];

export const MENU_USERS = [
  {
    title: 'Pessoas',
    path: AUTHORIZED_ROUTES.USERS_MANAGEMENT.PATH,
    iconName: 'UserGroup'
  }
];

export const MENU_ROUTINES = [
  {
    title: 'Rotinas',
    path: AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.PATH,
    iconName: 'TimeForward'
  }
];

export const MENU_DISPUTES = [
  {
    title: 'Acareações',
    path: AUTHORIZED_ROUTES.TRACKING.DISPUTES.PATH,
    iconName: 'FileTextQuestion'
  }
];

export const HELP_LEVE_URL =
  'https://ajuda.loggi.com/hc/pt-br/categories/4409724645261-Como-transportadora-Leve';
