import { colors } from '@loggi/mar';
import { FILTERS } from 'shared/filter/filter.constants';

const DISPUTE_STATUS_PENDING = 'pending';
const DISPUTE_STATUS_NON_PENDENCIES = 'non-pendencies';

const DISPUTE_DELIVERER_FILTER = {
  key: 'Quem entregou',
  btnFilter: 'Quem entregou',
  keyFilter: 'deliverer',
  whichFilter: FILTERS.DELIVERER_INCLUDING_INACTIVE,
  propertyFilter: 'deliverers'
};

const DISPUTE_RESPONSIBLE_FILTER = {
  key: 'Quem resolveu',
  btnFilter: 'Quem resolveu',
  keyFilter: 'dispute_responsible',
  whichFilter: FILTERS.DELIVERER_INCLUDING_INACTIVE,
  propertyFilter: 'deliverers'
};

const DISPUTE_RESPONSE_FILTER = {
  key: 'Resposta',
  btnFilter: 'Resposta',
  keyFilter: 'status',
  whichFilter: FILTERS.DISPUTES_STATUS,
  propertyFilter: 'solvedStatuses'
};

const DISPUTES_MAPPER = {
  [DISPUTE_STATUS_NON_PENDENCIES]: {
    TITLE: {
      finished: 'Acareações / ',
      disputes: 'Encerradas'
    },
    DISPUTE_BUTTON_FILTERS: [
      {
        id: 'to_allocate',
        label: 'ninguém alocado',
        keyFilter: 'without_dispute_responsible',
        keyValues: ['true']
      }
    ],
    DISPUTE_DROP_FILTERS: [
      DISPUTE_DELIVERER_FILTER,
      DISPUTE_RESPONSIBLE_FILTER,
      DISPUTE_RESPONSE_FILTER
    ]
  },
  [DISPUTE_STATUS_PENDING]: {
    TITLE: {
      finished: 'Acareações / ',
      disputes: 'Em aberto'
    },
    DISPUTE_BUTTON_FILTERS: [
      {
        id: 'to_expire',
        label: 'a expirar',
        keyFilter: 'range_date',
        keyValues: []
      },
      {
        id: 'to_allocate',
        label: 'ninguém alocado',
        keyFilter: 'without_dispute_responsible',
        keyValues: ['true']
      }
    ],
    DISPUTE_DROP_FILTERS: [DISPUTE_DELIVERER_FILTER]
  }
};

const MAPPER_STATUS = {
  Uninformed: {
    style: {
      borderRadius: '11px',
      backgroundColor: colors.red[100],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'inválido'
  },
  'Dispute Expiring': {
    style: {
      borderRadius: '11px',
      backgroundColor: colors.yellow[500],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'a expirar'
  },
  'Dispute Solved': {
    style: {
      borderRadius: '11px',
      backgroundColor: colors.smoke[100],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'respondido'
  },
  'Dispute Expired': {
    style: {
      borderRadius: '11px',
      backgroundColor: colors.red[100],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'expirado'
  }
};

const TEXT = {
  NONE_PACKAGE: 'Nenhum pacote',
  PACKAGE: 'pacote'
};

const DISPUTE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

const ERROR_TEXT = 'Erro ao buscar disputas';

const HIGHLIGHT_TEXTS = {
  expired: {
    title: 'O prazo para confirmar essa entrega expirou',
    subtitle: `A confirmação não foi enviada até $deadline e por isso o pacote foi extraviado. Tem algo errado? Fale com o suporte na Central de Ajuda.`
  },
  pendingWithResponsible: {
    title: 'Aguardando confirmação da entrega',
    subtitle: `As instruções estão disponíveis no aplicativo do entregador $responsible. O prazo para confirmar a entrega é `,
    deadline: `até $deadline.`
  },
  pedingWithoutResponsible: {
    title: 'Essa entrega precisa ser confirmada',
    subtitle: `Um entregador precisa confirmar a entrega com o destinatário `,
    deadline: `até $deadline.`
  }
};

const DRIVER_LIST_TEXTS = {
  formLabel: 'Selecione um entregador',
  cancelButton: 'Cancelar',
  confirmButton: 'Confirmar'
};

const UPDATE_RESPONSIBLE_TEXTS = {
  cancelButton: 'Cancelar',
  confirmButton: 'Confirmar',
  buttonWithResponsible: 'Alterar entregador',
  buttonWithoutResponsible: 'Alocar entregador',
  labelSuccessDriverAllocation: 'Entregador alocado com sucesso!',
  labelErrorDriverAllocation: 'Erro na alocação do entregador!',
  labelErrorGetDrivers: 'Erro na busca de entregadores'
};

const DEFAULT_DISPUTE_PER_PAGE = 20;

export {
  TEXT,
  ERROR_TEXT,
  MAPPER_STATUS,
  DISPUTE_DATE_FORMAT,
  HIGHLIGHT_TEXTS,
  DRIVER_LIST_TEXTS,
  UPDATE_RESPONSIBLE_TEXTS,
  DEFAULT_DISPUTE_PER_PAGE,
  DISPUTES_MAPPER,
  DISPUTE_STATUS_PENDING,
  DISPUTE_DELIVERER_FILTER,
  DISPUTE_RESPONSIBLE_FILTER,
  DISPUTE_STATUS_NON_PENDENCIES,
  DISPUTE_RESPONSE_FILTER
};
