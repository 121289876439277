import React, { useState } from 'react';

import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { useFsRealtimeGeneral } from '../../../infra/firebase/realtime/firebase-realtime-database';
import { AUTHORIZED_ROUTES, SWITCHES_REALTIME } from '../../../view/constants';
import { DIALOG_BODY, FECHAR, TO_PAGE } from './constants';

export default function DialogDisputesNewButtonComponent({ goToPage }) {
  const enableMenuDisputes = useFsRealtimeGeneral(
    SWITCHES_REALTIME.enableMenuDisputes
  );

  const showOnboarding =
    localStorage.getItem('DISPUTE_NEW_BUTTON_DIALOG_COMPLETE') !== 'true';

  const [
    showDisputesNewButtonDialog,
    setShowDisputesNewButtonDialog
  ] = useState(true);

  const setDialogComplete = () => {
    setShowDisputesNewButtonDialog(false);
    localStorage.setItem(
      'DISPUTE_NEW_BUTTON_DIALOG_COMPLETE',
      JSON.stringify(true)
    );
  };

  return enableMenuDisputes && showOnboarding ? (
    <ConfirmDialogComponent
      open={showDisputesNewButtonDialog}
      descriptionStylized={DIALOG_BODY.description}
    >
      <>
        <Button
          onClick={() => {
            setDialogComplete();
          }}
          color="primary"
          data-testid="close-dialog-submit-btn"
        >
          {FECHAR}
        </Button>
        <Button
          onClick={() => {
            setDialogComplete();
            goToPage(AUTHORIZED_ROUTES.TRACKING.DISPUTES.PATH);
          }}
          color="primary"
          variant="contained"
          data-testid="go-to-page-dialog-btn"
        >
          {TO_PAGE}
        </Button>
      </>
    </ConfirmDialogComponent>
  ) : null;
}

DialogDisputesNewButtonComponent.propTypes = {
  goToPage: PropTypes.func.isRequired
};
