import React from 'react';

import { Box, Typography } from '@material-ui/core';
import DisputesButtonGif from './Disputes_Button.gif';

export const FECHAR = 'Fechar';
export const TO_PAGE = 'Ir para Acareações';
export const TITLE = 'Acompanhe os pacotes com acareação';
export const DESCRIPTION =
  'Para melhorar a gestão da sua base, atualizamos a visualização de pacotes com pendência de acareação. Agora, eles aparecem na página ”Acareações” do menu superior.';

export const DIALOG_BODY = {
  description: (
    <>
      <img
        src={DisputesButtonGif}
        alt="Button Acareacoes"
        style={{ width: '100%', borderRadius: '0 0 10px 10px' }}
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingY="16px"
      >
        <Typography variant="h6">
          <em>{TITLE}</em>
        </Typography>
      </Box>
      <Typography variant="body1">{DESCRIPTION}</Typography>
    </>
  )
};
