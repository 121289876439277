import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import ConfigurableTable, {
  ConfigurableTableSkeleton
} from 'shared/configurable-table';
import Pagination from 'shared/pagination';
import AlertStatusHeader, {
  isPlatformStatusDegraded
} from 'shared/alert-status-header';
import FilterComponent from 'shared/filter';
import SelectComponent from 'shared/select';
import { colors } from '@loggi/mar';
import getConfigurableTableData, {
  ORDER_BY_VIEW_DISPUTES,
  DEFAULT_ORDER_BY_VIEW_DISPUTES
} from './disputes.configuration';
import disputesModel from './disputes.model';
import { TEXT_ALERT_STATUS_HEADER } from '../tracking.constants';
import DonwloadCsvReportContainer from './download/index';

const GroupFilterButton = ({ buttonFilters, handleButtonFilters }) => {
  const [clickedId, setClickedId] = useState();
  const [hasAnOptionSelected, setSelected] = useState(false);
  const activeColor = colors.blue[500];
  const inactiveColor = colors.smoke[500];

  const handleClick = (keyFilter, keyValues, id) => {
    const wasSelected = id === clickedId ? !hasAnOptionSelected : true;
    let key = keyFilter;
    let values = keyValues;
    if (wasSelected === false) {
      key = '';
      values = [];
    }

    handleButtonFilters(key, values);
    setSelected(wasSelected);
    setClickedId(id);
  };

  return buttonFilters.map(filter => (
    <Box mr={1} key={filter.id}>
      <Button
        className="button"
        variant="outlined"
        onClick={() => {
          handleClick(filter.keyFilter, filter.keyValues, filter.id);
        }}
        style={{
          height: '44px',
          borderColor:
            hasAnOptionSelected && clickedId === filter.id
              ? activeColor
              : inactiveColor,
          color:
            hasAnOptionSelected && clickedId === filter.id
              ? activeColor
              : inactiveColor
        }}
        size="small"
        data-testid={`btn-filter-${filter.id}`}
      >
        {filter.label}
      </Button>
    </Box>
  ));
};

GroupFilterButton.propTypes = {
  buttonFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      keyFilter: PropTypes.string,
      keyValues: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  handleButtonFilters: PropTypes.func.isRequired
};

function DisputesComponent({
  disputesData,
  loadingTable,
  onPageChange,
  pagination,
  handleFilterComponent,
  handleButtonFilters,
  filterReport,
  disputeStatus,
  disputesButtonFilters,
  disputesDropFilters,
  handlerSelectComponent
}) {
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY_VIEW_DISPUTES);

  const onOrderChange = item => {
    setOrderBy(item);
    handlerSelectComponent(item.orderBy);
  };

  return (
    <>
      <AlertStatusHeader
        textPrimary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.PRIMARY}
        textSecondary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.SECONDARY}
      />
      <Box p={5.5} minHeight={44}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <>
            <Box display="flex" flexWrap="wrap">
              {disputesDropFilters.map(filter => (
                <FilterComponent
                  key={filter.key}
                  btnFilter={filter.btnFilter}
                  whichFilter={filter.whichFilter}
                  onApplyChanges={items =>
                    handleFilterComponent(
                      items,
                      filter.keyFilter,
                      filter.propertyFilter
                    )
                  }
                />
              ))}
              <GroupFilterButton
                buttonFilters={disputesButtonFilters}
                handleButtonFilters={handleButtonFilters}
              />
            </Box>
            <Box display="flex" flexWrap="wrap">
              <Box px={1}>
                <SelectComponent
                  onSelectedChange={onOrderChange}
                  selected={orderBy}
                  selectOptions={ORDER_BY_VIEW_DISPUTES}
                  prefixLabel="Ordenar por"
                />
              </Box>
              <DonwloadCsvReportContainer filterReport={filterReport} />
            </Box>
          </>
        </Box>
        {!loadingTable && (
          <ConfigurableTable
            data={disputesData}
            columns={getConfigurableTableData(disputeStatus)}
            systemUnstable={isPlatformStatusDegraded()}
            withPackageDrawer
          />
        )}
        {loadingTable && <ConfigurableTableSkeleton />}

        <Box data-testid="disputes-pagination" mt={5}>
          <Pagination
            onPageChange={onPageChange}
            initialPage={parseInt(pagination.page, 10)}
            numberOfPages={parseInt(pagination.numberPages, 10)}
          />
        </Box>
      </Box>
    </>
  );
}

DisputesComponent.propTypes = {
  disputesData: PropTypes.arrayOf(PropTypes.shape(disputesModel)).isRequired,
  loadingTable: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.string,
    numberDisputesOnPage: PropTypes.string,
    numberPages: PropTypes.string,
    numberDisputesAllPages: PropTypes.string
  }).isRequired,
  handleFilterComponent: PropTypes.func.isRequired,
  handleButtonFilters: PropTypes.func.isRequired,
  filterReport: PropTypes.shape({
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    disputeFilters: PropTypes.string
  }).isRequired,
  disputeStatus: PropTypes.string.isRequired,
  disputesButtonFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      keyFilter: PropTypes.string,
      keyValues: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  disputesDropFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      btnFilter: PropTypes.string,
      keyFilter: PropTypes.string,
      whichFilter: PropTypes.string
    })
  ).isRequired,
  handlerSelectComponent: PropTypes.func.isRequired
};

export default DisputesComponent;
