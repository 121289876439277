import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, CircularProgress } from '@material-ui/core';

import getDeliverers from 'app/get-deliverers';
import getAggregationsCities from 'app/get-cities-attend';

import { fetchPackageAggregatedData } from 'tracking/card-aggregation/card-aggregation.service';
import { getCompanies } from 'tracking/in-base/base-collection/base-collection.service';

import { STATUS_CODE_GOING_TO_DELIVER } from 'shared/status-color-description/status-color-description.constants';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { getLoggedDCId } from 'auth/login/login.service';

import { getCirclesInformation } from 'information/routines-management/circles/circles.service';
import { getRegionNames } from 'information/routines-management/routines/complex-areas/complex-areas.service';

import STATUS_MAPPER from 'offer/cells-component/status-cell/status-cell.constants';
import { RANGE_SELECTOR_TEXT_TABLE } from 'offer/offer.constants';
import {
  CHARGE_TYPE_LABEL,
  CHARGE_TYPE
} from '../../finance/finance.constants';

import {
  LIST_STATUS,
  TEXT,
  FILTERS,
  DELIVERER_ADDITIONAL_INFO
} from './filter.constants';
import FilterComponent from './filter.component';
import FilterContext from './filter.context';
import useFilterChecked from './filter.hook';
import FilterGroupComponent from './group/group.component';

const WITH_FAULTS = 'with_faults';
const WITHOUT_FAULTS = 'without_faults';

export const DataForFilterShape = PropTypes.shape({
  deliverers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      code: PropTypes.number
    })
  ),
  hasFaults: PropTypes.arrayOf(PropTypes.bool),
  cities: PropTypes.arrayOf(PropTypes.string),
  circles: PropTypes.arrayOf(PropTypes.string),
  senders: PropTypes.arrayOf(PropTypes.string),
  withAlert: PropTypes.bool
});

function FilterPackagesContainer({
  onApplyChanges,
  status,
  btnFilter,
  titleArrayDeliverers,
  startDate,
  endDate,
  whichFilter,
  preselectedItems,
  baseFilters,
  checkedItemsBase,
  setCheckedItemsBase,
  dataForFilter
}) {
  const initialArray = [];

  const addUpdateFilter = () => {
    initialArray.push({
      title: 'Atualizações',
      key: 'updates',
      items: [
        {
          text: 'Não atualizados na última hora',
          value: moment()
            .subtract(1, 'hour')
            .format()
        }
      ]
    });
  };

  const addDelivererCodesFilter = () => {
    initialArray.push({
      key: 'delivererCodes',
      items: (dataForFilter.deliverers || []).map(item => ({
        text: item.name,
        value: item.code
      }))
    });
  };

  const addStreetStatusFilter = () => {
    initialArray.push({
      key: 'statusIds',
      items: (dataForFilter.statuses || []).map(item => ({
        text: item.text,
        value: item.code,
        style:
          LIST_STATUS.find(it => it.value === String(item.code))?.style || {}
      }))
    });
  };

  const addStreetFaultFilter = () => {
    initialArray.push({
      key: 'hasFault',
      items: (dataForFilter.hasFaults || []).map(item => ({
        text: item ? 'Com Falta' : 'Sem falta',
        value: item
      }))
    });
  };

  const addStreetCityFilter = () => {
    initialArray.push({
      key: 'cities',
      items: (dataForFilter.cities || []).map(item => {
        return {
          text: item,
          value: item
        };
      })
    });
  };

  const addStreetCircleFilter = () => {
    initialArray.push({
      key: 'circleIds',
      items: (dataForFilter.circles || []).map(item => {
        return {
          text: item.name,
          value: item.id
        };
      })
    });
  };

  const addStreetSenderFilter = () => {
    initialArray.push({
      key: 'senders',
      items: (dataForFilter.senders || []).map(item => {
        return {
          text: item,
          value: item
        };
      })
    });
  };

  const addWithAlertFilter = () => {
    if (dataForFilter?.withAlert) {
      initialArray.push({
        title: 'Com alerta',
        key: 'withAlert',
        items: [
          {
            text: 'Atrasados ou Insucesso',
            value: true
          }
        ]
      });
    }
  };

  const addStatusFilter = () => {
    initialArray.push({
      title: 'Status',
      key: 'status',
      items: LIST_STATUS.filter(item => {
        if (
          item.value === STATUS_CODE_GOING_TO_DELIVER &&
          getCompanyType() === 'LEVE'
        ) {
          return false;
        }
        return status.indexOf(item.value) >= 0;
      })
    });
  };

  const addStatusOferFilter = () => {
    initialArray.push({
      title: 'Situação',
      key: 'status',
      items: Object.values(STATUS_MAPPER)
    });
  };

  const addFaultsFilter = () => {
    initialArray.push({
      title: 'Faltas',
      key: 'faults',
      items: Object.values({
        [WITH_FAULTS]: {
          text: 'Com faltas',
          value: 'faults'
        },
        [WITHOUT_FAULTS]: {
          text: 'Sem faltas',
          value: 'no_faults'
        }
      })
    });
  };

  const addAlertsFilter = () => {
    initialArray.push({
      title: 'Alerts',
      key: 'qualityFlags',
      items: [
        {
          text: 'Problema na localização',
          value: 'has_suspicious_geocoding'
        }
      ]
    });
  };

  const addRangeOfferFilter = () => {
    initialArray.push({
      title: RANGE_SELECTOR_TEXT_TABLE.TITLE,
      key: 'timeIntervals',
      items: [
        {
          text: RANGE_SELECTOR_TEXT_TABLE.LATE,
          value: 'LATE'
        },
        {
          text: RANGE_SELECTOR_TEXT_TABLE.FOR_NOW,
          value: 'FOR_NOW'
        },
        {
          text: RANGE_SELECTOR_TEXT_TABLE.FOR_TODAY,
          value: 'FOR_TODAY'
        },
        {
          text: RANGE_SELECTOR_TEXT_TABLE.FOR_LATER,
          value: 'FOR_LATER'
        },
        {
          text: RANGE_SELECTOR_TEXT_TABLE.FINISHED,
          value: 'FINISHED'
        }
      ]
    });
  };

  const addStationFilter = () => {
    initialArray.push({
      title: 'Estação',
      key: 'station',
      items: [
        {
          text: 'Recebimento',
          value: 'COL'
        },
        {
          text: 'Processamento',
          value: 'SEP'
        }
      ]
    });
  };

  const [hasFetched, setHasFetched] = useState(false);
  const [arrayFilter, setArrayFilter] = useState(initialArray);

  const { setCheckedItems, updateSavedCheckedCount } = useContext(
    FilterContext
  );

  const fetchCities = async () => {
    const response = await getAggregationsCities();
    setHasFetched(true);
    setArrayFilter(_array => {
      return [
        ..._array,
        {
          title: 'Escolha uma ou mais opções',
          key: 'cities',
          items: response
            .map(o => {
              return {
                value: o.id,
                text: o.label
              };
            })
            .sort((a, b) => {
              return a.text.localeCompare(b.text);
            })
        }
      ];
    });
  };

  const fetchRegionAggregatedData = async () => {
    const response = await fetchPackageAggregatedData({
      aggregatedField: 'region_label',
      startDate,
      endDate
    });
    setHasFetched(true);
    setArrayFilter(_array => {
      return [
        ..._array,
        {
          title: 'Região',
          key: 'region',
          items: response.data
            .filter(o => o.id !== '-')
            .sort((a, b) => {
              return a.label.toUpperCase().localeCompare(b.label.toUpperCase());
            })
            .map(o => {
              return { value: o.id, text: o.label };
            })
        }
      ];
    });
  };

  const fetchDeliverers = async () => {
    const hasDC = Boolean(getLoggedDCId());

    const includeInactive =
      FILTERS.DELIVERER_INCLUDING_INACTIVE === whichFilter && hasDC;

    const getAdditionalInfo = deliverer => {
      if (deliverer.isDriver) {
        return ' - '.concat(DELIVERER_ADDITIONAL_INFO.NEW);
      }
      if (deliverer.isActive) {
        return '';
      }
      return ' - '.concat(DELIVERER_ADDITIONAL_INFO.OLD);
    };

    const responseDeliverers = await getDeliverers(false, includeInactive);
    setHasFetched(true);
    setArrayFilter(_array => {
      return [
        ..._array,
        {
          title: titleArrayDeliverers,
          key: 'deliverers',
          items: responseDeliverers
            .map(o => {
              return {
                value: o.id,
                text: includeInactive
                  ? o.fullName.concat(getAdditionalInfo(o))
                  : o.fullName
              };
            })
            .sort((a, b) => {
              return a.text.localeCompare(b.text);
            })
        }
      ];
    });
  };

  const fetchSenders = async () => {
    const responseCompanies = await getCompanies();
    setHasFetched(true);
    setArrayFilter(_array => {
      return [
        ..._array,
        {
          title: 'Quem enviou',
          key: 'senders',
          items: responseCompanies.data.map(companyInfo => {
            return {
              value: companyInfo.id,
              text: companyInfo.label
            };
          })
        }
      ];
    });
  };

  const fetchCircles = async () => {
    const response = await getCirclesInformation();
    setHasFetched(true);
    if (response.circles.length === 0) return;
    setArrayFilter(_array => {
      return [
        ..._array,
        {
          title: 'Círculo',
          key: 'circles',
          items: response.circles
            .map(circle => {
              return {
                value: circle.id,
                text: circle.name
              };
            })
            .sort((a, b) => {
              return a.text.localeCompare(b.text);
            })
        }
      ];
    });
  };

  const fetchRegionOffer = async () => {
    const response = await getRegionNames();
    setHasFetched(true);
    setArrayFilter(_array => {
      return [
        ..._array,
        {
          title: 'Região',
          key: 'region',
          items: response.map(o => {
            return { value: o.id, text: o.label };
          })
        }
      ];
    });
  };

  const addChargeTypeFilter = () => {
    const chargeTypes = [
      {
        text: CHARGE_TYPE_LABEL.CUSTOMER_PICKUP_PAYMENT,
        value: CHARGE_TYPE.CUSTOMER_PICKUP_PAYMENT
      },
      {
        text: CHARGE_TYPE_LABEL.TRANSHIPMENT_PAYMENT,
        value: CHARGE_TYPE.TRANSHIPMENT_PAYMENT
      },
      {
        text: CHARGE_TYPE_LABEL.COUNTER_PICKUP_PAYMENT,
        value: CHARGE_TYPE.COUNTER_PICKUP_PAYMENT
      }
    ];

    const items = [
      {
        text: CHARGE_TYPE_LABEL.DELIVERY_PAYMENT,
        value: CHARGE_TYPE.DELIVERY_PAYMENT
      },
      ...chargeTypes.filter(type => status.includes(type.value))
    ];

    initialArray.push({
      title: FILTERS.CHARGE_TYPE,
      key: 'chargeType',
      items
    });
  };

  const addDisputesStatusFilter = () => {
    initialArray.push({
      title: 'Resposta',
      key: 'solvedStatuses',
      items: [
        {
          text: 'Expirado',
          value: 'expired'
        },
        {
          text: 'Respondido',
          value: 'solved'
        }
      ]
    });
  };

  const dataForFilterFunctions = {
    [FILTERS.IN_STREET_DELIVERER]: addDelivererCodesFilter,
    [FILTERS.IN_STREET_STATUS]: addStreetStatusFilter,
    [FILTERS.IN_STREET_HAS_FAULT]: addStreetFaultFilter,
    [FILTERS.IN_STREET_CITY]: addStreetCityFilter,
    [FILTERS.IN_STREET_CIRCLE]: addStreetCircleFilter,
    [FILTERS.IN_STREET_SENDER]: addStreetSenderFilter,
    [FILTERS.WITH_ALERT]: addWithAlertFilter
  };

  if (dataForFilter && dataForFilterFunctions[whichFilter]) {
    dataForFilterFunctions[whichFilter]();
  }

  const onOpenFetchFunctions = {
    [FILTERS.CITIES]: fetchCities,
    [FILTERS.REGION]: fetchRegionAggregatedData,
    [FILTERS.DELIVERER]: fetchDeliverers,
    [FILTERS.DELIVERER_INCLUDING_INACTIVE]: fetchDeliverers,
    [FILTERS.SENDER]: fetchSenders,
    [FILTERS.CIRCLES]: fetchCircles,
    [FILTERS.REGION_OFFER]: fetchRegionOffer
  };

  const onOpen = () => {
    if (!hasFetched && onOpenFetchFunctions[whichFilter]) {
      onOpenFetchFunctions[whichFilter]();
    }
  };

  const filterFunctions = {
    [FILTERS.UPDATE]: addUpdateFilter,
    [FILTERS.STATUS]: addStatusFilter,
    [FILTERS.STATUS_OFFER]: addStatusOferFilter,
    [FILTERS.FAULTS]: addFaultsFilter,
    [FILTERS.ALERTS]: addAlertsFilter,
    [FILTERS.RANGE_OFFER]: addRangeOfferFilter,
    [FILTERS.STATION]: addStationFilter,
    [FILTERS.CHARGE_TYPE]: addChargeTypeFilter,
    [FILTERS.DISPUTES_STATUS]: addDisputesStatusFilter
  };

  const filterFunction = filterFunctions[whichFilter];
  if (filterFunction) {
    filterFunction();
  }

  useState(() => {
    if (preselectedItems.length > 0) {
      setCheckedItems(initialArray[0].key, preselectedItems);
      updateSavedCheckedCount(preselectedItems.length);
    }
  }, []);

  return (
    <FilterComponent
      onApplyChanges={onApplyChanges}
      onOpen={onOpen}
      btnFilter={btnFilter}
      baseFilters={baseFilters}
    >
      {arrayFilter.map(filterGroup => {
        return (
          <FilterGroupComponent
            key={filterGroup.key}
            group={filterGroup}
            checkedItemsBase={checkedItemsBase}
            setCheckedItemsBase={setCheckedItemsBase}
          />
        );
      })}
      {!hasFetched &&
        [FILTERS.DELIVERER, FILTERS.REGION, FILTERS.SENDER].includes(
          whichFilter
        ) && (
          <Box p={4} display="flex" justifyContent="center">
            <CircularProgress data-testid="loading" />
          </Box>
        )}
    </FilterComponent>
  );
}

FilterPackagesContainer.propTypes = {
  onApplyChanges: PropTypes.func.isRequired,
  status: PropTypes.arrayOf(PropTypes.string),
  btnFilter: PropTypes.string,
  titleArrayDeliverers: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  whichFilter: PropTypes.string.isRequired,
  preselectedItems: PropTypes.arrayOf(PropTypes.string),
  baseFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  checkedItemsBase: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  setCheckedItemsBase: PropTypes.func,
  dataForFilter: DataForFilterShape
};

FilterPackagesContainer.defaultProps = {
  status: [],
  btnFilter: TEXT.BTN_FILTER,
  startDate: null,
  endDate: null,
  preselectedItems: [],
  baseFilters: {},
  checkedItemsBase: {},
  setCheckedItemsBase: null,
  dataForFilter: null
};

export default function FilterPackagesContextWrapper({
  onApplyChanges,
  status,
  btnFilter,
  titleArrayDeliverers,
  startDate,
  endDate,
  whichFilter,
  preselectedItems,
  baseFilters,
  checkedItemsBase,
  setCheckedItemsBase,
  dataForFilter
}) {
  const {
    checkedItems,
    toogleCheckedItem,
    resetCheckedItems,
    setCheckedItems,
    updateSavedCheckedCount,
    getSavedCheckedCount
  } = useFilterChecked({});

  const contextValue = useMemo(
    () => ({
      checkedItems,
      toogleCheckedItem,
      resetCheckedItems,
      setCheckedItems,
      updateSavedCheckedCount,
      getSavedCheckedCount
    }),
    [
      checkedItems,
      toogleCheckedItem,
      resetCheckedItems,
      setCheckedItems,
      updateSavedCheckedCount,
      getSavedCheckedCount
    ]
  );

  return (
    <FilterContext.Provider value={contextValue}>
      <FilterPackagesContainer
        onApplyChanges={onApplyChanges}
        status={status}
        btnFilter={btnFilter}
        titleArrayDeliverers={titleArrayDeliverers}
        startDate={startDate}
        endDate={endDate}
        whichFilter={whichFilter}
        preselectedItems={preselectedItems}
        baseFilters={baseFilters}
        checkedItemsBase={checkedItemsBase}
        setCheckedItemsBase={setCheckedItemsBase}
        dataForFilter={dataForFilter}
      />
    </FilterContext.Provider>
  );
}

FilterPackagesContextWrapper.propTypes = {
  onApplyChanges: PropTypes.func.isRequired,
  status: PropTypes.arrayOf(PropTypes.string),
  btnFilter: PropTypes.string,
  titleArrayDeliverers: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  whichFilter: PropTypes.string.isRequired,
  preselectedItems: PropTypes.arrayOf(PropTypes.string),
  baseFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  checkedItemsBase: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  setCheckedItemsBase: PropTypes.func,
  dataForFilter: DataForFilterShape
};

FilterPackagesContextWrapper.defaultProps = {
  status: [],
  btnFilter: TEXT.BTN_FILTER,
  titleArrayDeliverers: 'Entregadores',
  startDate: null,
  endDate: null,
  preselectedItems: [],
  baseFilters: {},
  checkedItemsBase: {},
  setCheckedItemsBase: null,
  dataForFilter: null
};
