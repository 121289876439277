import React, { useEffect, useState, useContext, useCallback } from 'react';

import moment from 'moment';

import { useHistory } from 'react-router-dom';

import { Grid, Typography, Button, Box, Divider } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Skeleton } from '@material-ui/lab';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

import { ReactComponent as IconRua } from 'images/ic-rua-sm.svg';
import { ReactComponent as IconEntregue } from 'images/ic-entregue-sm.svg';
import { ReactComponent as IconBase } from 'images/ic-barcode.svg';
import { ReactComponent as IconFalta } from 'images/ic-falta.svg';
import { ReactComponent as IconCheckToday } from 'images/ic-check-bgw.svg';
import { ReactComponent as TruckImage } from 'images/illustra-caminhao.svg';
import { ReactComponent as IconVaiChegar } from 'images/ic-vai-chegar.svg';

import CardIcon from 'shared/card-icon';
import InformationBox from 'shared/information-box/information-box.component';
import FilterComponent from 'shared/filter';

import { AUTHORIZED_ROUTES, SWITCHES } from 'view/constants';

import CUSTODY_MAP from 'tracking/tracking.configuration';
import { VIEWS, AGG_FIELDS, KEY_CUSTODY } from 'tracking/tracking.constants';
import { fetchPackageAggregatedData } from 'tracking/card-aggregation/card-aggregation.service';
import { SummaryContext } from 'tracking/template/summary.context';
import { TIME } from 'tracking/date-range-picker/date-range-picker.constants';

import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';

import {
  Can,
  OptionsForDC,
  IsFSActiveForCurrentBase
} from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';

import useStyles from 'information/today/today.style';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { formatNumber } from 'shared/formatters';
import OfferTodayCard from 'offer/today-card';
import { ONBOARDING_PERMISSIONAMENTO } from 'shared/onboarding-drawer/onboarding-drawer.configuration';
import OnBoardingDrawerComponent from 'shared/onboarding-drawer';
import DialogDisputesNewButtonComponent from 'shared/confirm-dialog/disputes-new-button/dialog-disputes-new-button.component';
import {
  TEXT_CARD,
  ARRAY_FILTERS,
  TEXT_ALERT_STATUS_HEADER
} from './today.constants';
import CardInformation from '../card-information/card-information.component';
import {
  DAY_ZERO,
  CONGRATS
} from '../card-information/card-information.constants';
import ArrivalsCard from '../cards/arrivals-card.container';

export default function TodayContainer() {
  const { setSummary } = useContext(SummaryContext);

  const [isLoadingNaRua, setLoadingNaRua] = useState(true);
  const [isLoadingNaBase, setLoadingNaBase] = useState(true);
  const [isLoadingEntregues, setLoadingEntregues] = useState(true);
  const [isLoadingLsdFinalizados, setLoadingLsdFinalizados] = useState(true);
  const [isLoadingFalta, setLoadingFaltas] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [dataNaRua, setDataNaRua] = useState({ data: [] });
  const [dataNaBase, setDataNaBase] = useState({ data: [] });
  const [dataEntregues, setDataEntregues] = useState({ data: [] });
  const [dataLsdFinalizados, setDataLsdFinalizados] = useState({ data: [] });
  const [dataFaltas, setDataFaltas] = useState({});
  const [filters, setFilters] = useState({});
  const [card, setCard] = useState();
  const [subtitle, setSubtitle] = useState('');
  const [totalPackages, setTotalPackages] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [openOnBoarding, setOpenOnBoarding] = useState(false);

  const hasFSOnboardingV2 = IsFSActiveForCurrentBase(
    SWITCHES.enableOnboardingV2
  );

  const history = useHistory();
  const goToPage = url => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${url}`);
  };

  const classes = useStyles();

  useEffect(() => {
    setSummary(
      <Typography variant="h4">
        <strong>{TEXT_CARD.TEXT_SUMMARY}</strong>
      </Typography>
    );
  }, [setSummary]);

  useEffect(() => {
    const isLoading =
      isLoadingNaBase ||
      isLoadingNaRua ||
      isLoadingEntregues ||
      isLoadingLsdFinalizados;
    if (
      !isLoading &&
      !dataNaBase.data &&
      !dataNaRua.data &&
      !dataEntregues.data &&
      !dataLsdFinalizados.data
    ) {
      setCard(
        <Box maxWidth="630px">
          <CardInformation
            textResult={DAY_ZERO.TEXT}
            textBtn={DAY_ZERO.TEXT_BUTTON}
            url={DAY_ZERO.URL}
          >
            <TruckImage />
          </CardInformation>
        </Box>
      );
    } else if (
      !isLoading &&
      !dataNaBase.data &&
      !dataNaRua.data &&
      (dataEntregues.data || dataLsdFinalizados.data)
    ) {
      setCard(
        <CardInformation
          textResult={`${formatNumber(dataEntregues.total)} ${
            CONGRATS.TEXT_RESULT
          }`}
          textBtn={CONGRATS.TEXT_BUTTON}
          url={CONGRATS.URL}
        >
          <IconCheckToday />
        </CardInformation>
      );
    } else {
      setCard();
    }
  }, [
    dataNaBase,
    dataNaRua,
    dataEntregues,
    dataLsdFinalizados,
    isLoadingNaBase,
    isLoadingNaRua,
    isLoadingEntregues,
    isLoadingLsdFinalizados
  ]);

  useEffect(() => {
    async function fetchNaBase() {
      setDataNaBase(
        await fetchPackageAggregatedData({
          aggregatedField: AGG_FIELDS.PACKAGE_STATE,
          view: VIEWS.NA_BASE
        })
      );
      setLoadingNaBase(false);
    }
    fetchNaBase().catch(error => {
      showSnackbar({
        message: error.message,
        enqueueSnackbar,
        variant: 'error'
      });
    });

    const startDate = moment()
      .set(TIME.START_DAY)
      .utc()
      .format();
    const endDate = moment()
      .set(TIME.END_DAY)
      .utc()
      .format();

    async function fetchEntregues() {
      setDataEntregues(
        await fetchPackageAggregatedData({
          aggregatedField: AGG_FIELDS.PERIOD,
          startDate,
          endDate,
          view: VIEWS.ENTREGUES,
          dateFilter: CUSTODY_MAP[KEY_CUSTODY.FINALIZADOS].dateFilter
        })
      );
      setLoadingEntregues(false);
    }
    fetchEntregues().catch(error => {
      showSnackbar({
        message: error.message,
        enqueueSnackbar,
        variant: 'error'
      });
    });

    async function fetchLsdFinalizados() {
      setDataLsdFinalizados(
        await fetchPackageAggregatedData({
          aggregatedField: AGG_FIELDS.PERIOD_LSD,
          startDate,
          endDate,
          view: VIEWS.FINALIZADOS,
          dateFilter: CUSTODY_MAP[KEY_CUSTODY.FINALIZADOS].dateFilter
        })
      );
      setLoadingLsdFinalizados(false);
    }
    fetchLsdFinalizados().catch(error => {
      showSnackbar({
        message: error.message,
        enqueueSnackbar,
        variant: 'error'
      });
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    async function fetchNaRua() {
      setIsFiltered(
        filters?.deliverers?.length > 0 || filters?.updates?.length > 0
      );
      setDataNaRua(
        await fetchPackageAggregatedData({
          filters,
          aggregatedField: AGG_FIELDS.STATUS_CODE,
          view: VIEWS.NA_RUA
        })
      );
      setLoadingNaRua(false);
    }

    fetchNaRua().catch(error => {
      showSnackbar({
        message: error.message,
        enqueueSnackbar,
        variant: 'error'
      });
    });
  }, [enqueueSnackbar, filters]);

  useEffect(() => {
    async function fetchFaltas() {
      setDataFaltas(
        await fetchPackageAggregatedData({
          aggregatedField: AGG_FIELDS.MISSED_EVENTS
        })
      );
      setLoadingFaltas(false);
    }
    fetchFaltas().catch(error => {
      showSnackbar({
        message: error.message,
        enqueueSnackbar,
        variant: 'error'
      });
    });
  }, [enqueueSnackbar]);

  const onApplyChanges = items => {
    if (items.remove) {
      setFilters(current => {
        const currentState = current;
        delete currentState[items.remove];
        return { ...currentState };
      });
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }
    setLoadingNaRua(true);
  };

  const sumTotalPackages = useCallback((totalCard = 0) => {
    setTotalPackages(total => total + totalCard);
  }, []);

  useEffect(() => {
    const count =
      totalPackages +
      dataNaBase.total +
      dataNaRua.total +
      dataEntregues.total +
      (dataLsdFinalizados?.total || 0);
    switch (count) {
      case 0:
        setSubtitle(`${TEXT_CARD.NONE} ${TEXT_CARD.PACKAGE}`);
        break;
      case 1:
        setSubtitle(`${count} ${TEXT_CARD.PACKAGE}`);
        break;
      default:
        setSubtitle(`${formatNumber(count)} ${TEXT_CARD.PACKAGE}s`);
        break;
    }
  }, [dataEntregues, dataNaBase, dataNaRua, dataLsdFinalizados, totalPackages]);

  useEffect(() => {
    const loading =
      isLoadingNaBase ||
      isLoadingNaRua ||
      isLoadingEntregues ||
      isLoadingLsdFinalizados;
    setSummary(
      <>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant="h4">
              <strong>{TEXT_CARD.TEXT_SUMMARY}</strong>
              {!loading && (
                <>
                  <br />
                  {subtitle}
                </>
              )}
              {loading && (
                <Box pt={0.5} pb={0.5}>
                  <Skeleton variant="rect" width="150px" height="35px" />
                </Box>
              )}
            </Typography>
            {card}
          </Grid>
        </Grid>
      </>
    );
  }, [
    setSummary,
    card,
    isLoadingNaBase,
    isLoadingNaRua,
    isLoadingEntregues,
    isLoadingLsdFinalizados,
    subtitle
  ]);

  const loadingSkeleton = () => {
    return (
      <Grid container spacing={10}>
        <Grid item>
          <Skeleton variant="rect" height={72} width={72} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={32} width={42} />
          <br />
          <Skeleton variant="rect" height={19} width={100} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={32} width={42} />
          <br />
          <Skeleton variant="rect" height={19} width={100} />
        </Grid>
      </Grid>
    );
  };

  const loadingSkeletonSmall = () => {
    return <Skeleton variant="rect" height={72} width={72} />;
  };

  const InStreetComponent = () => {
    const enableNewInStreet = IsFSActiveForCurrentBase(
      SWITCHES.enableNewInStreet
    );

    return (
      (dataNaRua.data || isFiltered) && (
        <CardIcon
          variant="primary"
          icon={IconRua}
          showAlertStatusHeaderWhenUnstable
          alertStatusHeaderText={TEXT_ALERT_STATUS_HEADER.IN_STREET}
        >
          <Grid container>
            <Grid item>
              <Box pt={0.5}>
                <Typography variant="h6">
                  <em>{TEXT_CARD.NA_RUA.CARD_TITLE}</em>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="medium"
                  className={classes.buttomPos}
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={() => {
                    const _path = enableNewInStreet
                      ? AUTHORIZED_ROUTES.INFORMATION.IN_STREET
                      : AUTHORIZED_ROUTES.TRACKING.IN_STREET;
                    goToPage(_path);
                  }}
                >
                  {TEXT_CARD.NA_RUA.CARD_LINK}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={10}>
            <Grid item xs>
              {isLoadingNaRua && (
                <Skeleton variant="rect" height={72} width={72} />
              )}
              {!isLoadingNaRua && (
                <>
                  <Typography variant="h2">
                    {formatNumber(dataNaRua.total) || 0}
                  </Typography>
                  {!dataNaRua.data && !dataNaRua.total && (
                    <>
                      <Typography color="textSecondary">
                        {TEXT_CARD.NA_RUA.NO_PACKAGES}
                      </Typography>
                      <Typography color="textSecondary">
                        {TEXT_CARD.NA_RUA.NO_PACKAGES_MORE}
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs>
              <Typography align="right">
                {ARRAY_FILTERS.map(item => {
                  return (
                    <FilterComponent
                      key={item}
                      btnFilter={item}
                      onApplyChanges={onApplyChanges}
                      whichFilter={item}
                    />
                  );
                })}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs>
              {dataNaRua.data && !isLoadingNaRua && (
                <InformationBox chartData={dataNaRua} />
              )}
            </Grid>
          </Grid>
        </CardIcon>
      )
    );
  };

  const actionAfterFinishOnboarding = () => {
    setOpenOnBoarding(false);
  };

  // onBoarding
  useEffect(() => {
    if (
      hasFSOnboardingV2 &&
      JSON.parse(
        localStorage.getItem(ONBOARDING_PERMISSIONAMENTO.KEY_LOCAL_STORAGE)
      ) !== true
    ) {
      setOpenOnBoarding(true);
    }
  }, [hasFSOnboardingV2]);

  const viewAllOnboarding = useCallback(() => {
    if (
      JSON.parse(
        localStorage.getItem(ONBOARDING_PERMISSIONAMENTO.KEY_LOCAL_STORAGE)
      ) !== true
    ) {
      localStorage.setItem(
        ONBOARDING_PERMISSIONAMENTO.KEY_LOCAL_STORAGE,
        JSON.stringify(true)
      );
    }
  }, []);

  return (
    <>
      <DialogDisputesNewButtonComponent goToPage={goToPage} />
      {openOnBoarding && (
        <Can actions={[ACTIONS.ONBOARDING_PERMISSIONAMENTO]}>
          <OnBoardingDrawerComponent
            configurations={ONBOARDING_PERMISSIONAMENTO}
            onClose={() => setOpenOnBoarding(false)}
            actionAfterFinishOnboarding={actionAfterFinishOnboarding}
            setViewAllOnboarding={() =>
              viewAllOnboarding(ONBOARDING_PERMISSIONAMENTO.keyLocalStorage)
            }
          />
        </Can>
      )}

      {!card && (
        <>
          <Can
            DCCapability="collection_center"
            whenDC={OptionsForDC.IS_IN_WHATEVER_STATE}
            actions={[ACTIONS.OFFER_READ]}
          >
            <OfferTodayCard
              goToOfferViewPressed={() =>
                goToPage(AUTHORIZED_ROUTES.OFFER.LIST)
              }
            />
          </Can>

          <Can
            whenDC={OptionsForDC.IS_PRESENT}
            actions={[ACTIONS.ARRIVALS_READ]}
          >
            <CardIcon
              variant="primary"
              icon={IconVaiChegar}
              showAlertStatusHeaderWhenUnstable
              alertStatusHeaderText={TEXT_ALERT_STATUS_HEADER.ARRIVALS}
            >
              <Grid container>
                <Grid item>
                  <Box pt={0.5} pb={1}>
                    <Typography variant="h6">
                      <em>{TEXT_CARD.VAI_CHEGAR.CARD_TITLE}</em>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="text"
                      color="primary"
                      size="medium"
                      className={classes.buttomPos}
                      endIcon={<ArrowForwardIosIcon />}
                      onClick={() =>
                        goToPage(AUTHORIZED_ROUTES.TRACKING.ARRIVALS)
                      }
                    >
                      {TEXT_CARD.VAI_CHEGAR.CARD_LINK}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <br />
              <ArrivalsCard sumTotalPackages={sumTotalPackages} />
            </CardIcon>
          </Can>

          {dataNaBase.data && (
            <Can actions={[ACTIONS.TRACKING_READ, ACTIONS.TRACKING_OPERATOR]}>
              <CardIcon
                variant="secondary"
                icon={IconBase}
                showAlertStatusHeaderWhenUnstable
                alertStatusHeaderText={TEXT_ALERT_STATUS_HEADER.IN_BASE}
              >
                <Grid container>
                  <Grid item>
                    <Box pt={0.5}>
                      <Typography variant="h6">
                        <em>{TEXT_CARD.NA_BASE.CARD_TITLE}</em>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="text"
                        color="primary"
                        size="medium"
                        className={classes.buttomPos}
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={() =>
                          goToPage(AUTHORIZED_ROUTES.INFORMATION.BASE)
                        }
                      >
                        {TEXT_CARD.NA_BASE.CARD_LINK}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <br />
                {!isLoadingNaBase && (
                  <Grid container spacing={10} alignItems="center">
                    <Grid item>
                      <Typography variant="h2">
                        {formatNumber(dataNaBase.total)}
                      </Typography>
                    </Grid>
                    {dataNaBase.data &&
                      dataNaBase.data.map(data => {
                        return (
                          <Grid item key={data.id}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                            >
                              {data.hasMissedDate && (
                                <StopRoundedIcon
                                  color="error"
                                  data-testid="missed-event-box"
                                />
                              )}
                              <Typography variant="subtitle1">
                                <em>{data.value}</em>
                              </Typography>
                            </Box>
                            <Typography color="textSecondary" variant="body2">
                              <em>
                                {data.label ||
                                  TEXT_CARD.NA_BASE.CARD_BODY[data.id]}
                              </em>
                            </Typography>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}

                {isLoadingNaBase && loadingSkeleton()}
              </CardIcon>
            </Can>
          )}

          <InStreetComponent />

          <Grid container direction="row" spacing={4}>
            {dataEntregues.data && (
              <Can actions={[ACTIONS.FINISHED_READ]}>
                <Grid container item md>
                  <CardIcon
                    variant="success"
                    icon={IconEntregue}
                    showAlertStatusHeaderWhenUnstable
                    alertStatusHeaderText={TEXT_ALERT_STATUS_HEADER.FINISHED}
                  >
                    <Grid container>
                      <Grid item>
                        <Box pt={0.5}>
                          <Typography variant="h6">
                            <em>{TEXT_CARD.FINALIZADOS.CARD_TITLE}</em>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Box display="flex" justifyContent="flex-end" mb={1}>
                          <Button
                            variant="text"
                            color="primary"
                            size="medium"
                            className={classes.buttomPos}
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              goToPage(AUTHORIZED_ROUTES.TRACKING.FINISHED)
                            }
                          >
                            {TEXT_CARD.FINALIZADOS.CARD_LINK}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>

                    {!isLoadingEntregues && !isLoadingLsdFinalizados && (
                      <Grid container spacing={10} justify="space-between">
                        <Grid item>
                          <Typography variant="h2">
                            {formatNumber(
                              dataEntregues.total + dataLsdFinalizados.total
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          xs
                          container
                          item
                          wrap="nowrap"
                          spacing={4}
                          justify="space-between"
                        >
                          {dataEntregues.data?.map(delivered => {
                            return (
                              <Grid item key={delivered.id}>
                                <Box py={0.5}>
                                  <Typography variant="subtitle1">
                                    <em>{delivered.value}</em>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                    display="block"
                                  >
                                    <em>
                                      {delivered.label ||
                                        TEXT_CARD.FINALIZADOS.CARD_BODY[
                                          delivered.id
                                        ]}
                                    </em>
                                  </Typography>
                                </Box>
                              </Grid>
                            );
                          })}
                          <Grid item xs={1} key="dividerToLsd">
                            <Divider orientation="vertical" />
                          </Grid>
                          {dataLsdFinalizados.data?.map(d => (
                            <Grid item key={d.label}>
                              <Box py={0.5}>
                                <Typography variant="subtitle1">
                                  <em>{d.value}</em>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                  display="inline"
                                >
                                  <em>
                                    {TEXT_CARD.FINALIZADOS.CARD_BODY[d.label] ||
                                      d.label}
                                  </em>
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    {(isLoadingEntregues || isLoadingLsdFinalizados) &&
                      loadingSkeleton()}
                  </CardIcon>
                </Grid>
              </Can>
            )}
            <Can actions={[ACTIONS.MISSED_PROMISED_DATE_READ]}>
              <Grid container item xs={4}>
                <CardIcon variant="error" icon={IconFalta}>
                  <Grid item>
                    <Box pt={0.5}>
                      <Typography variant="h6">
                        <em>{TEXT_CARD.FALTAS.CARD_TITLE}</em>
                      </Typography>
                    </Box>
                  </Grid>
                  <br />
                  {!isLoadingFalta && (
                    <Grid item>
                      <Typography variant="h2">
                        {formatNumber(dataFaltas.total)}
                      </Typography>
                    </Grid>
                  )}
                  {isLoadingFalta && loadingSkeletonSmall()}
                </CardIcon>
              </Grid>
            </Can>
          </Grid>
        </>
      )}
    </>
  );
}
