import React from 'react';
import { useRouteMatch, Redirect, Switch } from 'react-router-dom';

import CanRouteTemplate from 'shared/can-route-template';

import Today from 'information/today';
import Base from 'tracking/in-base';
import InStreetRoutes from 'in-street';
import Offer from 'offer';
import OfferRouteMap from 'offer/offer-route-map';
import Tracking from 'tracking';
import Arrivals from 'tracking/arrivals';
import ToTransfer from 'tracking/to-transfer';
import Disputes from 'tracking/disputes';

import DelivererPackageAssociation from 'view/pages/deliverer-package-association';
import ChooseDelivererScreen from 'view/pages/choose-deliverer';
import getDeliverers from 'app/get-deliverers';

import Finance from 'finance';
import SummaryValues from 'finance/summary-values';

import UsersManagement from 'users-management';

import RoutinesManagement from 'information/routines-management';

import DriversTracking from 'drivers-tracking';

import { ACTIONS } from 'auth/access-control/access-control.rules';
import ComplexAreasComponent from 'information/routines-management/routines/complex-areas';
import DemandRegionsComponent from 'information/routines-management/routines/demand-regions';

import { AUTHORIZED_ROUTES } from './constants';

export default function AuthorizedRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* * information * */}
      <CanRouteTemplate
        withNewLayout
        path={`${path}${AUTHORIZED_ROUTES.INFORMATION.TODAY}`}
        component={Today}
      />
      <CanRouteTemplate
        actions={[ACTIONS.OFFER_READ]}
        exact
        path={`${path}${AUTHORIZED_ROUTES.OFFER.LIST}`}
        withNewLayout
        component={Offer}
      />
      <CanRouteTemplate
        actions={[ACTIONS.OFFER_READ]}
        exact
        path={`${path}${AUTHORIZED_ROUTES.OFFER.DETAIL}`}
        withNewLayout
        component={Offer}
      />
      <CanRouteTemplate
        actions={[ACTIONS.OFFER_READ]}
        exact
        path={`${path}${AUTHORIZED_ROUTES.OFFER.ROUTE_MAP}`}
        withNewLayout
        component={OfferRouteMap}
      />

      {/* * tracking * */}

      <CanRouteTemplate
        actions={[ACTIONS.ARRIVALS_READ]}
        path={`${path}${AUTHORIZED_ROUTES.TRACKING.ARRIVALS}`}
        withNewLayout
        component={Arrivals}
      />

      <CanRouteTemplate
        actions={[ACTIONS.FINISHED_READ]}
        path={`${path}${AUTHORIZED_ROUTES.TRACKING.DISPUTES.PATH}`}
        withNewLayout
        component={Disputes}
      />

      <CanRouteTemplate
        path={`${path}${AUTHORIZED_ROUTES.TRACKING.PATH}/:custody${
          AUTHORIZED_ROUTES.TRACKING.PARAMS_VALIDATION_IN_GROUPS
        }${AUTHORIZED_ROUTES.TRACKING.IN_GROUPS}`}
        component={() => <Tracking withLargeUnitload />}
      />
      <CanRouteTemplate
        path={`${path}${AUTHORIZED_ROUTES.TRACKING.PATH}/:custody${
          AUTHORIZED_ROUTES.TRACKING.PARAMS_VALIDATION
        }`}
        component={Tracking}
      />
      <CanRouteTemplate
        path={`${path}${
          AUTHORIZED_ROUTES.TRACKING.PATH
        }/:custody(resultado)/:argument`}
        component={Tracking}
      />
      <CanRouteTemplate
        actions={[ACTIONS.TO_TRANSFER_READ]}
        path={`${path}${AUTHORIZED_ROUTES.TRACKING.TO_TRANSFER}/:itemType?`}
        component={ToTransfer}
        withNewLayout
      />

      {/* * new base * */}
      <CanRouteTemplate
        path={`${path}${AUTHORIZED_ROUTES.INFORMATION.BASE}`}
        withNewLayout
        component={Base}
      />

      <CanRouteTemplate
        path={`${path}${AUTHORIZED_ROUTES.INFORMATION.HIDDEN_BASE}`}
        withNewLayout
        component={Base}
      />

      {/* * move * */}
      <CanRouteTemplate
        actions={[ACTIONS.ASSIGNMENT_READ]}
        path={`${path}${AUTHORIZED_ROUTES.MOVE.DELIVERERS}/:id`}
        component={DelivererPackageAssociation}
      />

      <CanRouteTemplate
        actions={[ACTIONS.ASSIGNMENT_READ]}
        path={`${path}${AUTHORIZED_ROUTES.MOVE.DELIVERERS}`}
        component={() => (
          <ChooseDelivererScreen getDeliverers={getDeliverers} />
        )}
      />

      {/* * finance * */}
      <CanRouteTemplate
        actions={[ACTIONS.FINANCE_READ]}
        path={`${path}${AUTHORIZED_ROUTES.FINANCE.SUMMARY}`}
        withNewLayout
        component={SummaryValues}
      />
      <CanRouteTemplate
        actions={[ACTIONS.FINANCE_READ]}
        path={`${path}${AUTHORIZED_ROUTES.FINANCE.PATH}/:period${
          AUTHORIZED_ROUTES.FINANCE.PARAMS_VALIDATION
        }`}
        withNewLayout
        component={Finance}
      />

      {/* * users-management * */}
      <CanRouteTemplate
        actions={[ACTIONS.USER_MANAGEMENT_READ]}
        withNewLayout
        path={`${path}${AUTHORIZED_ROUTES.USERS_MANAGEMENT.PATH}`}
        component={UsersManagement}
      />

      {/* * activities * */}
      <CanRouteTemplate
        path={`${path}${AUTHORIZED_ROUTES.DRIVERS_TRACKING}`}
        withNewLayout
        component={DriversTracking}
      />

      {/* * routines * */}
      <CanRouteTemplate
        actions={[ACTIONS.ROUTINES_READ]}
        withNewLayout
        path={`${path}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.PATH}`}
        component={RoutinesManagement}
      />

      <CanRouteTemplate
        actions={[ACTIONS.ROUTINES_READ]}
        path={`${path}${AUTHORIZED_ROUTES.ROUTINES.COMPLEX_AREAS}`}
        withNewLayout
        component={ComplexAreasComponent}
      />

      <CanRouteTemplate
        actions={[ACTIONS.ROUTINES_READ]}
        path={`${path}${AUTHORIZED_ROUTES.ROUTINES.DEMAND_REGIONS}`}
        withNewLayout
        component={DemandRegionsComponent}
      />

      {/* * new na-rua * */}
      <CanRouteTemplate
        path={`${path}${AUTHORIZED_ROUTES.INFORMATION.IN_STREET}`}
        withNewLayout
        component={InStreetRoutes}
      />

      <Redirect to={`${path}${AUTHORIZED_ROUTES.INFORMATION.TODAY}`} />
    </Switch>
  );
}
