import React, { useState } from 'react';

import {
  useHistory,
  useRouteMatch,
  Redirect,
  Switch,
  Route
} from 'react-router-dom';
import { Box, Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

import { AUTHORIZED_ROUTES } from 'view/constants';
import {
  DISPUTE_STATUS_PENDING,
  DISPUTE_STATUS_NON_PENDENCIES
} from '../disputes.constants';

import DisputesContainer from '../disputes.container';

const TAB = {
  PENDING: 0,
  CLOSED: 1
};

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${colors.smoke[200]}`
  }
});

export default function DisputesManagementContainer() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const style = useStyles();
  const [value, setValue] = useState(
    history.location.pathname.indexOf(
      AUTHORIZED_ROUTES.TRACKING.DISPUTES.CLOSED
    ) >= 0
      ? TAB.CLOSED
      : TAB.PENDING
  );

  const tabs = ['Em aberto', 'Encerradas'];

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === TAB.PENDING) {
      history.push(`${url}${AUTHORIZED_ROUTES.TRACKING.DISPUTES.PENDING}`);
    } else {
      history.push(`${url}${AUTHORIZED_ROUTES.TRACKING.DISPUTES.CLOSED}`);
    }
  };

  return (
    <Paper component={Box} elevation={10} flexGrow={1} marginBottom={10}>
      <Box pt={1} px={5.5}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          className={style.root}
        >
          {tabs.map(tab => (
            <Tab label={tab} key={tab} />
          ))}
        </Tabs>
      </Box>
      <Switch>
        <Route
          path={`${path}${AUTHORIZED_ROUTES.TRACKING.DISPUTES.PENDING}`}
          render={() => (
            <DisputesContainer
              key={DISPUTE_STATUS_PENDING}
              status={DISPUTE_STATUS_PENDING}
            />
          )}
        />
        <Route
          path={`${path}${AUTHORIZED_ROUTES.TRACKING.DISPUTES.CLOSED}`}
          render={() => (
            <DisputesContainer
              key={DISPUTE_STATUS_NON_PENDENCIES}
              status={DISPUTE_STATUS_NON_PENDENCIES}
            />
          )}
        />
        <Redirect
          to={{
            pathname: `${url}${AUTHORIZED_ROUTES.TRACKING.DISPUTES.PENDING}`
          }}
        />
      </Switch>
    </Paper>
  );
}
