import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Grid, Typography, Button, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import { ReactComponent as ICEntregue } from 'images/ic-entregue.svg';
import { ReactComponent as ICRua } from 'images/ic-rua.svg';
import { ReactComponent as ICResult } from 'images/ic-result.svg';
import { ReactComponent as ICBase } from 'images/ic-base.svg';

import { AUTHORIZED_ROUTES, SWITCHES } from 'view/constants';
import { formatNumber } from 'shared/formatters';
import {
  Can,
  IsFSActiveForCurrentBase,
  getCompanyType
} from 'auth/access-control';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import EmptyList from 'shared/empty-list';
import DateRangePicker from './date-range-picker';
import { SUMMARY, KEY_CUSTODY, TOGGLE } from './tracking.constants';
import { useStyles } from './tracking.styles';
import CUSTODY_MAP from './tracking.configuration';
import ToggleButton from './toggle-button/toggle-button.component';

export default function TrackingSummaryComponent({
  loading,
  custody,
  count,
  searchArgument,
  hasPackages,
  error,
  downloadCsvLink,
  changeDate,
  startDate,
  endDate,
  handleAlignment,
  withLargeUnitload
}) {
  const [subtitle, setSubtitle] = useState('');

  const classes = useStyles();
  const content = CUSTODY_MAP[custody];

  const history = useHistory();
  const location = useLocation();
  const handleBackCollections = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.INFORMATION.BASE}`);
  };

  const handleGoToDisputesPage = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.TRACKING.DISPUTES.PATH}`);
  };

  const enableShowDisputesPage = IsFSActiveForCurrentBase(
    'enableShowDisputesPage'
  );

  const enableNaRuaToggleGroupForLeve = IsFSActiveForCurrentBase(
    SWITCHES.enableNaRuaToggleGroupForLeve
  );

  const enableNewInStreet = IsFSActiveForCurrentBase(
    SWITCHES.enableNewInStreet
  );

  const getToggleTitle = lmcType => {
    if (custody === KEY_CUSTODY.NA_BASE) return TOGGLE.TITLE_GROUPS[custody];
    return TOGGLE.TITLE_GROUPS[custody][lmcType];
  };

  const shouldEnableToggleButton = (currentCustody, fs) => {
    return (
      (currentCustody === KEY_CUSTODY.NA_RUA &&
        (getCompanyType() === 'AGENCY' || fs)) ||
      currentCustody === KEY_CUSTODY.NA_BASE
    );
  };

  useEffect(() => {
    if (content.isDatePickerDisplayed) {
      setSubtitle(SUMMARY.RANGE_DATE);
    } else if (content.slug === KEY_CUSTODY.RESULTADO) {
      switch (count) {
        case 0:
          setSubtitle(`${SUMMARY.NONE} ${SUMMARY.RESULT}`);
          break;
        case 1:
          setSubtitle(`${count} ${SUMMARY.RESULT} ${SUMMARY.FOUND}`);
          break;
        default:
          setSubtitle(
            `${formatNumber(count)} ${SUMMARY.RESULT}s ${SUMMARY.FOUND}s`
          );
          break;
      }
    } else {
      if (count === 1) {
        setSubtitle(`${count} ${SUMMARY.PACKAGE}`);
        return;
      }
      if (count === 0) {
        setSubtitle(`${SUMMARY.NONE} ${SUMMARY.PACKAGE}`);
        return;
      }
      setSubtitle(`${formatNumber(count)} ${SUMMARY.PACKAGE}s`);
    }
  }, [count, content]);

  return enableNewInStreet &&
    location.pathname.includes('/acompanhamento/na-rua') ? (
    <Grid
      container
      direction="row"
      alignItems="center"
      display="flex"
      justify="space-between"
      style={{ marginBottom: '-20px' }}
    >
      <Grid item xs>
        <Box display="flex" alignItems="center">
          {loading ? (
            <Skeleton height={60} width="40%" animation="wave" />
          ) : (
            <>
              <Box display="flex" alignItems="center" mr={1}>
                <IconButton
                  size="small"
                  edge="start"
                  onClick={() => {
                    const rc = getSelectedRoutingCode();
                    const path = `/${rc}/na-rua${
                      AUTHORIZED_ROUTES.INFORMATION.COLLECTION
                    }`;
                    history.push(path);
                  }}
                >
                  <Box
                    color="primary.contrastText"
                    display="flex"
                    alignItems="center"
                  >
                    <ArrowBackRoundedIcon data-testid="back-base-button" />
                  </Box>
                </IconButton>
              </Box>
              <Typography variant="h4">Na rua</Typography>
              <Box ml={1}>
                <Typography variant="h4">
                  <strong>{` / Entregadores`}</strong>
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {loading ? (
          <Skeleton height={60} width="20%" animation="wave" />
        ) : (
          <Box mt={2}>
            <Typography variant="h4">{subtitle}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="row"
      alignItems="center"
      display="flex"
      justify="space-between"
    >
      <Grid item xs>
        {content.isSubView ? (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={1}>
              <IconButton
                size="small"
                edge="start"
                onClick={handleBackCollections}
              >
                <Box
                  color="primary.contrastText"
                  display="flex"
                  alignItems="center"
                >
                  <ArrowBackRoundedIcon data-testid="back-base-button" />
                </Box>
              </IconButton>
            </Box>
            <Typography variant="h4">{`${content.title} /`}</Typography>
            <Box ml={1}>
              <Typography variant="h4">
                <strong>{`${content.subView}`}</strong>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant="h4">
            <strong>
              {content.title}
              {content.slug === KEY_CUSTODY.RESULTADO && ` ${searchArgument}`}
            </strong>
          </Typography>
        )}

        {loading && (
          <Box pt={0.5} pb={0.5}>
            <Skeleton
              variant="rect"
              width="150px"
              className={classes.skeleton}
              height="35px"
            />
          </Box>
        )}
        {!loading && (
          <>
            <Typography variant="h4">
              {subtitle}{' '}
              {content.isDatePickerDisplayed && (
                <DateRangePicker
                  handleTrackingService={changeDate}
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                />
              )}
            </Typography>
            {!hasPackages && content.slug !== KEY_CUSTODY.NA_BASE && (
              <EmptyList
                text={
                  !error ? content.emptyDetail : SUMMARY.EMPTY_PACKAGE_DEFAULT
                }
                buttonLabel={
                  content.isSubView ? SUMMARY.GO_TO_BASE : SUMMARY.GO_TO_TODAY
                }
                buttonPath={
                  content.isSubView
                    ? AUTHORIZED_ROUTES.INFORMATION.BASE
                    : AUTHORIZED_ROUTES.INFORMATION.TODAY
                }
              >
                {content.slug === KEY_CUSTODY.FINALIZADOS && (
                  <ICEntregue data-testid="ic-entregue" />
                )}
                {content.slug === KEY_CUSTODY.NA_RUA && (
                  <ICRua data-testid="ic-rua" />
                )}
                {content.slug === KEY_CUSTODY.RESULTADO && (
                  <ICResult data-testid="ic-resultado" />
                )}
                {content.isSubView && <ICBase />}
              </EmptyList>
            )}
          </>
        )}
      </Grid>
      <Grid item align="center">
        <Grid container direction="row" spacing={2}>
          {content.slug === KEY_CUSTODY.NA_BASE && (
            <Can excludeFS="disableButtonDownloadReportForDcs">
              <Button
                variant="outlined"
                color="default"
                size="medium"
                target="_blank"
                href={downloadCsvLink}
              >
                {SUMMARY.DOWNLOAD_REPORT}
              </Button>
            </Can>
          )}
          {hasPackages &&
            shouldEnableToggleButton(
              custody,
              enableNaRuaToggleGroupForLeve
            ) && (
              <Can>
                <Grid item data-testid="na-rua-group-toggle">
                  <ToggleButton
                    handleAlignment={handleAlignment}
                    withLargeUnitload={withLargeUnitload}
                    titleGroup={getToggleTitle(getCompanyType(true))}
                    titlePackages={TOGGLE.TITLE_PACKAGES}
                    valueGroup={TOGGLE.VALUE_GROUPS}
                    valuePackages={TOGGLE.VALUE_PACKAGES}
                    blueBackground
                  />
                </Grid>
              </Can>
            )}
        </Grid>
      </Grid>
      <Grid item align="center">
        {content.slug === KEY_CUSTODY.FINALIZADOS && enableShowDisputesPage && (
          <Button
            variant="outlined"
            color="default"
            size="medium"
            onClick={handleGoToDisputesPage}
            data-testid="go-to-disputes-page"
          >
            {SUMMARY.WITH_PENDING}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

TrackingSummaryComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  custody: PropTypes.string.isRequired,
  count: PropTypes.number,
  searchArgument: PropTypes.string,
  hasPackages: PropTypes.bool.isRequired,
  error: PropTypes.string,
  downloadCsvLink: PropTypes.string,
  changeDate: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  handleAlignment: PropTypes.func.isRequired,
  withLargeUnitload: PropTypes.bool.isRequired
};

TrackingSummaryComponent.defaultProps = {
  searchArgument: null,
  count: null,
  startDate: null,
  endDate: null,
  error: null,
  downloadCsvLink: null
};
