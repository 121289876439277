import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Skeleton } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from 'tracking/tracking.styles';
import { SummaryContext } from 'tracking/template/summary.context';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import {
  TEXT,
  DISPUTE_DATE_FORMAT,
  ERROR_TEXT,
  DISPUTES_MAPPER
} from './disputes.constants';

import DisputesComponent from './disputes.component';
import { DEFAULT_ORDER_BY_VIEW_DISPUTES } from './disputes.configuration';
import { getDisputesData } from './disputes.service';

function DisputesContainer({ status }) {
  const { enqueueSnackbar } = useSnackbar();
  const [{ disputes = [], pagination = {} }, setDisputesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPackages, setTotalPackages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [delivererFilter, setDelivererFilter] = useState({});
  const [buttonsFilter, setButtonsFilter] = useState({});
  const defaultDisputeFilters = { key: 'status', values: [status] };
  const [disputeFilters, setDisputeFilters] = useState(
    `{};${JSON.stringify(defaultDisputeFilters)}`
  );
  const { setSummary } = useContext(SummaryContext);
  const classes = useStyles();
  const history = useHistory();
  const defaultEndDateTime = moment(new Date())
    .add(10, 'days')
    .format(DISPUTE_DATE_FORMAT);
  const defaultStartDateTime = moment(defaultEndDateTime)
    .subtract(40, 'days')
    .format(DISPUTE_DATE_FORMAT);
  const [endDateTime, setEndDateTime] = useState(defaultEndDateTime);
  const [startDateTime, setStartDateTime] = useState(defaultStartDateTime);
  const [disputeStatus] = useState(status);
  const [orderBy, setOrderBy] = useState(
    DEFAULT_ORDER_BY_VIEW_DISPUTES.orderBy
  );

  const countPackageDisplay = count => {
    switch (count) {
      case 0:
        return TEXT.NONE_PACKAGE;
      case 1:
        return `${count} ${TEXT.PACKAGE}`;
      default:
        return `${count} ${TEXT.PACKAGE}s`;
    }
  };

  const [filterReport, setFilterReport] = useState({
    startDateTime,
    endDateTime,
    disputeFilters
  });

  useEffect(() => {
    getDisputesData({
      startDateTime,
      endDateTime,
      currentPage,
      disputeFilters,
      orderBy
    })
      .then(currentDisputesData => {
        setDisputesData(currentDisputesData);
        setTotalPackages(
          parseInt(currentDisputesData.pagination.numberDisputesAllPages, 10)
        );
        setLoading(false);
        setFilterReport({
          startDateTime,
          endDateTime,
          disputeFilters
        });
      })
      .catch(error => {
        showSnackbar({
          message: `${ERROR_TEXT} (${error.message})`,
          variant: 'error',
          enqueueSnackbar
        });
      });
  }, [
    setLoading,
    startDateTime,
    endDateTime,
    currentPage,
    disputeFilters,
    enqueueSnackbar,
    disputeStatus,
    orderBy
  ]);

  useEffect(() => {
    setSummary(
      <>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" data-testid="title">
            {DISPUTES_MAPPER[disputeStatus].TITLE.finished}
            <strong>{DISPUTES_MAPPER[disputeStatus].TITLE.disputes}</strong>
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h4">
            {loading ? (
              <Skeleton
                variant="rect"
                width="150px"
                className={classes.skeleton}
                height="42px"
              />
            ) : (
              countPackageDisplay(totalPackages)
            )}
          </Typography>
        </Box>
      </>
    );
  }, [
    setSummary,
    history,
    loading,
    totalPackages,
    classes.skeleton,
    disputeStatus
  ]);

  const shouldApplyDefaultStatusFilter = (firstFilter, secondFilter) => {
    const hasStatusFilter = filter =>
      filter && filter.key === 'status' && filter.values.length > 0;
    return !(hasStatusFilter(firstFilter) || hasStatusFilter(secondFilter));
  };

  const createDisputeFilters = (firstFilter, secondFilter) => {
    let generalFilter = `${JSON.stringify(firstFilter)};${JSON.stringify(
      secondFilter
    )}`;

    if (shouldApplyDefaultStatusFilter(firstFilter, secondFilter)) {
      generalFilter = `${generalFilter};${JSON.stringify(
        defaultDisputeFilters
      )}`;
    }

    setDisputeFilters(generalFilter);
  };

  const handlePagination = page => {
    setCurrentPage(parseInt(page, 10));
  };

  const handleFilterComponent = (items, keyFilter, propertyFilter) => {
    const response = { ...items[propertyFilter] };
    const responseValues = Object.values(response).reduce(
      (a, b) => a.concat(b),
      []
    );

    let currentFilter = {};
    if (responseValues.length > 0) {
      currentFilter = {
        key: keyFilter,
        values: responseValues
      };
    }

    setLoading(true);
    setCurrentPage(1);
    setDelivererFilter(currentFilter);
    createDisputeFilters(currentFilter, buttonsFilter);
  };

  const handlerSelectComponent = item => {
    setOrderBy(item);
    setCurrentPage(1);
    setLoading(true);
  };

  const handleButtonFilters = (filterKey, filterValues) => {
    if (filterKey === 'range_date') {
      setStartDateTime(moment(new Date()).format(DISPUTE_DATE_FORMAT));
      setEndDateTime(
        moment(new Date())
          .add(1, 'days')
          .format(DISPUTE_DATE_FORMAT)
      );
    } else {
      setStartDateTime(defaultStartDateTime);
      setEndDateTime(defaultEndDateTime);
      const buttonFilter = {
        key: filterKey,
        values: filterValues
      };
      setButtonsFilter(buttonFilter);
      createDisputeFilters(delivererFilter, buttonFilter);
    }

    setLoading(true);
    setCurrentPage(1);
  };

  return (
    <DisputesComponent
      disputesData={disputes}
      loadingTable={loading}
      onPageChange={handlePagination}
      pagination={pagination}
      handleFilterComponent={handleFilterComponent}
      handleButtonFilters={handleButtonFilters}
      filterReport={filterReport}
      disputeStatus={disputeStatus}
      disputesButtonFilters={
        DISPUTES_MAPPER[disputeStatus].DISPUTE_BUTTON_FILTERS
      }
      disputesDropFilters={DISPUTES_MAPPER[disputeStatus].DISPUTE_DROP_FILTERS}
      handlerSelectComponent={handlerSelectComponent}
    />
  );
}

DisputesContainer.propTypes = {
  status: PropTypes.string.isRequired
};

export default DisputesContainer;
