/* eslint-disable camelcase */
import axios from 'axios';

import * as Sentry from '@sentry/browser';

import { getCredentials, updateCredentials } from 'infra/storage/credential';

import {
  MOVE_PACKAGES_URL,
  GET_LOGGERS,
  GET_DEFINED_REGIONS,
  GET_UNIT_LOAD_URL,
  UPDATE_DEFINED_REGIONS
} from 'infra/service/constants';

import {
  getSelectedBase,
  fetchAndForceOrSelectFirst
} from 'profile/profile.service';

import { getAuthorizationTokens, getHeaders } from '@loggi/authentication-lib';

import { getCompanyType } from 'auth/access-control';

import { getSelectedRoutingCode } from 'auth/login/login.service';

function createApi() {
  const baseURL = `${process.env.REACT_APP_LEV_URL}`;
  const axiosConfig = {
    baseURL
  };
  return axios.create(axiosConfig);
}

let isFetching = false;

const heldRequests = []; // NOSONAR
const heldRequestsDC = [];
const api = createApi();

const getToken = async () => {
  const { idToken } = await getAuthorizationTokens();

  // If there is not a logged user, getAuthorizationTokens will return idToken and accessToken is null.
  if (idToken) {
    return idToken;
  }

  return null;
};

const addCustomHeaders = async config => {
  const _config = config;

  // If there is not a Authorization header, it means that it
  // wasnt logged with cognito.
  _config.headers = await getHeaders(_config.headers);

  _config.headers.service = 'arco';

  return _config;
};

const addVersion = async config => {
  const _config = { ...config };
  _config.headers.appVersion = process.env.REACT_APP_ARCO_VERSION;
  return _config;
};

// util function to send message to Sentry
function sentryMessage(_request, message, fingerprint, level) {
  let parsedData;
  let parsedParams;
  const parsedBase = getSelectedBase();
  try {
    parsedData = JSON.parse(_request.config?.data);
  } catch (err) {
    parsedData = _request.config?.data || '';
  }

  try {
    parsedParams = JSON.parse(_request.config?.params);
  } catch (err) {
    parsedParams = _request.config?.params || '';
  }

  return scope => {
    scope.setFingerprint(fingerprint);
    Sentry.setExtra('request', {
      url: _request.config?.url || '',
      data: parsedData,
      params: parsedParams
    });
    Sentry.setExtra('response', {
      data: _request.response?.data,
      status: _request.response?.status
    });
    Sentry.setTag(
      'routing_code',
      // eslint-disable-next-line camelcase
      parsedBase?.distribution_center?.routing_code
    );
    Sentry.setExtra('base', parsedBase);
    Sentry.captureMessage(message, level);
  };
}

const addDistributionCenterLastMileAccess = config => {
  const _config = { ...config };

  // endpoints to ignore "request enrichment"
  // login, refresh, proxy/move/package, loggers
  if (
    config.url === '/login' ||
    config.url === '/oauth/access_token' ||
    config.url === '/proxy-unretryable/last-mile/v1/leve/move/package_list' ||
    config.url === MOVE_PACKAGES_URL ||
    config.url === GET_LOGGERS ||
    config.url === `${GET_DEFINED_REGIONS}/${getSelectedRoutingCode()}` ||
    config.url === UPDATE_DEFINED_REGIONS ||
    config.url?.startsWith(GET_UNIT_LOAD_URL)
  ) {
    return _config;
  }

  const base = getSelectedBase();
  if (base) {
    const param = _config.method === 'get' ? 'params' : 'data';

    _config.url = _config.url
      .replace(
        '[last_mile_company.identification]',
        base?.last_mile_company?.identification
      ) // for users without DC we replace it for 0
      .replace('[distribution_center.id]', base?.distribution_center?.id || 0);

    // when a post request is being held, the data body stored is a string
    // since we are going to manipulate it, we have to parse it back to JSON
    if (typeof _config[param] === 'string') {
      _config[param] = JSON.parse(_config[param]);
    }

    _config[param] = {
      ..._config[param],
      distribution_center_id: Number(base?.distribution_center?.id) || 0,
      last_mile_company_id: base.last_mile_company.identification,
      routing_code: base?.distribution_center?.routing_code || null
    };
  }

  // if no DC is selected, throw error to be handled on response interceptor
  if (!base && config.url !== '/arco/api/v1/distribution_center') {
    const skipXHRError = new Error('waitForDC');
    skipXHRError.waitForDC = true;
    skipXHRError.config = config;
    throw skipXHRError;
  }

  return _config;
};

async function doRefreshToken() {
  const refreshToken = getCredentials().refresh_token;
  const refreshTokenGrantType = 'refresh_token';

  const requestPayload = {
    refresh_token: refreshToken,
    grant_type: refreshTokenGrantType
  };

  try {
    const refreshTokenPath = '/oauth/access_token';
    const userTokenResponse = await api.post(refreshTokenPath, requestPayload);
    updateCredentials(userTokenResponse.data);
  } catch (err) {
    window.location = '/';
  }
}

function holdRequest(originalRequest) {
  const _originalRequest = { ...originalRequest };
  return new Promise(resolve => {
    heldRequests.push(token => {
      _originalRequest.headers.Authorization = `Bearer ${token}`;
      resolve(api(_originalRequest));
    });
  });
}

function holdRequestDC(originalRequest) {
  const _originalRequest = { ...originalRequest };
  return new Promise(resolve => {
    heldRequestsDC.push(() => {
      resolve(api(_originalRequest));
    });
  });
}

function onDCFetched() {
  isFetching = false;

  return heldRequestsDC.map(holdedRequestPromise => {
    return holdedRequestPromise();
  });
}

// https://axios-http.com/docs/interceptors
const errorResponseInterceptor = error => {
  return Promise.reject(error); // NOSONAR
};

const dcInterceptor = error => {
  if (error.waitForDC) {
    const { config } = error;

    if (!isFetching) {
      isFetching = true;
      fetchAndForceOrSelectFirst().then(data => {
        // we will only re-do the pending requests if there is any response from list distribution center
        if (
          data.lastmilecompanyDistributioncenter &&
          data.lastmilecompanyDistributioncenter.length > 0
        ) {
          onDCFetched();
        }
      });
    }
    return holdRequestDC(config);
  }
  return Promise.reject(error); // NOSONAR
};

const generateLevePath = async path => {
  const base = getSelectedBase();
  const companyType = getCompanyType();

  const replacedPath = path
    .replace(
      '[last_mile_company.identification]',
      base.last_mile_company.identification
    )
    .replace('[distribution_center.id]', base?.distribution_center?.id ?? 0);

  const url = new URL(`${process.env.REACT_APP_LEV_URL}${replacedPath}`);
  const token = await getToken();
  url.searchParams.set('token', token);
  url.searchParams.set(
    'distribution_center_id',
    Number(base?.distribution_center?.id ?? 0)
  );
  url.searchParams.set(
    'last_mile_company_id',
    base.last_mile_company.identification
  );
  url.searchParams.set(
    'routing_code',
    base?.distribution_center?.routing_code ?? 0
  );
  url.searchParams.set('companyType', companyType);

  return url.toString();
};

api.interceptors.request.use(addCustomHeaders);
api.interceptors.request.use(addVersion);
api.interceptors.request.use(addDistributionCenterLastMileAccess);

// Intercept Error('waitForDC') for fetch and select list of available DCs
api.interceptors.response.use(response => response, dcInterceptor);

// Intercept the response before it reaches the user, in order to retry
// it with a new token in case we get a 401 UNAUTHORIZED response
api.interceptors.response.use(response => {
  return response;
}, errorResponseInterceptor);

export {
  addCustomHeaders,
  addVersion,
  addDistributionCenterLastMileAccess,
  doRefreshToken,
  errorResponseInterceptor,
  dcInterceptor,
  holdRequest,
  getToken,
  generateLevePath,
  sentryMessage
};
export default api;
