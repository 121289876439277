import React from 'react';

import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { Icon } from '@loggi/loggi-icons';
import { generateLevePath } from 'infra/api/leve';
import { URL_API_V1_DISPUTES_PREFIX } from 'infra/service/constants';

function DonwloadCsvReportContainer({ filterReport }) {
  const generateCsvLink = async filter => {
    const { startDateTime, endDateTime, disputeFilters } = filter;
    const path = `${URL_API_V1_DISPUTES_PREFIX}/report?startDateTime=${startDateTime}&endDateTime=${endDateTime}&filters=&disputeFilters=${disputeFilters}`;
    return generateLevePath(path);
  };

  const handleDownloadClick = async () => {
    const csvLink = await generateCsvLink(filterReport);
    window.open(csvLink, '_blank');
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      target="_blank"
      onClick={handleDownloadClick}
    >
      <Box mr={1}>
        <Icon name="ArrowDownload" size="small" color={colors.blue[500]} />
      </Box>
      Baixar relatório
    </Button>
  );
}

DonwloadCsvReportContainer.propTypes = {
  filterReport: PropTypes.shape({
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    disputeFilters: PropTypes.string
  }).isRequired
};

export default DonwloadCsvReportContainer;
